import { exportFournisseurNoFt } from "@/api/fournisseurManagement";
export const namespaced = true;

export const state = {};

//mutation du state: permet de setter le state
export const mutations = {};

export const actions = {
  async fetchExportFournisseurNoFt({ commit }, data) {
    return await exportFournisseurNoFt(data);
  }
};
