import { getMessage } from "@/api/messageAdmin";
import { updateMessage } from "@/api/messageAdmin";
export const namespaced = true;

export var state = {
  adminMessage: {}
};

//mutation du state: permet de setter le state
export const mutations = {
  setAdminMessage(state, data) {
    state.adminMessage = data;
  }
};

export const actions = {
  async getMessageAdmin({ commit }, data) {
    commit("setAdminMessage", await getMessage(data));
  },

  async updateMessageAdmin({ commit }, data) {
    return await updateMessage(data);
  }
};
