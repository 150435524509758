<template>
  <v-menu
    bottom
    offset-y
    transition="scale-transition"
    class="border-radius-tile"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        slot="activator"
        v-bind="$attrs"
        v-on="on"
        v-html="data.label"
        depressed
        class="primary white--text"
        :class="[{ 'v-btn--active': isActive }, ...classBtn]"
      ></v-btn>
    </template>
    <v-list class="primary">
      <v-list-item v-for="(row, i) in data.navs" :key="i" class="pa-0">
        <NavBarBtn block :data="row" class="primary white--text"></NavBarBtn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import NavBarBtn from "@/components/nav/NavBarBtn";
//import { mapState } from "vuex";

export default {
  props: {
    data: Object,
    classBtn: Array
  },

  computed: {
    // ...mapState("route", ["path"]),
    isActive() {
      // return this.data.navs.findIndex(r => r.path == this.path) >= 0;
      return "";
    }
  },

  components: { NavBarBtn }
};
</script>
