import {
  getUserInfo,
  getToken,
  getUser,
  createUser,
  checkLoginAlreadyUse
} from "@/api/user";
export const namespaced = true;

export var state = {
  userInfo: {},
  token: null,
  nom: null,
  prenom: null
};

//mutation du state: permet de setter le state
export const mutations = {
  setToken(state, data) {
    if (data == null) {
      state.token = null;
      window.localStorage.clear();
    } else {
      state.token = data.token;
      window.localStorage.setItem("token", data.token);
    }
  },
  setUser(state, data) {
    state.nom = data.nom;
    state.prenom = data.prenom;
    state.group = data.group;
  },
  setUserInfo(state, data) {
    state.userInfo = data;
  }
};

export const actions = {
  async getToken({ commit }, data) {
    commit("setToken", await getToken(data));
  },
  async getUser({ commit }, token) {
    commit("setUser", await getUser(token));
  },
  async getUserInfo({ commit }, token) {
    commit("setUserInfo", await getUserInfo(token));
  },
  async fetchCreateUser(context, params) {
    return await createUser(params);
  },
  async checkLogin(context, params) {
    return await checkLoginAlreadyUse(params);
  }
};
