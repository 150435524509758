import { userMenu } from "@/api/menus";
import { flatMapDeep } from "lodash";
export const namespaced = true;

export const state = {
  menus: [],
  withNav: false
};

//mutation du state: permet de setter le state
export const mutations = {
  setMenus(state, data) {
    state.menus = data;
  },
  setWithNav(state, data) {
    state.withNav = data;
  }
};

export const actions = {
  async fetchMenus({ commit }, param) {
    commit("setMenus", await userMenu(param));
  }
};

export const getters = {
  barNavs(state) {
    return flatMapDeep(state.menus.map(r => recurseNavBar(r)));
  },
  listEnquete(state, rootState) {
    const locale = rootState.locale;
    let t = [];
    state.listEnquete.forEach(r => {
      if (r.value < 0) r.text = i18n.t("userManagement.dialogUser." + r.text);
      t.push(r);
    });
    return t;
  }
};

function recurseNavBar(r) {
  if (r.navs) return r.navs.map(row => recurseNavBar(row));
  return r;
}
