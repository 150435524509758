import Vue from "vue";
import Vuex from "vuex";
//import le store menus
import * as clients from "@/store/clients";
import * as espaceCuisine from "@/store/espaceCuisine";
import * as menus from "@/store/menus";
import * as messageAdmin from "@/store/messageAdmin";
import * as articleManagement from "@/store/articleManagement";
import * as upload from "@/store/upload";
import * as user from "@/store/user";
import * as FtManagement from "@/store/ficheTechniqueManagement";
import * as fournisseurManagement from "@/store/fournisseurManagement";
Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    menus,
    clients,
    messageAdmin,
    espaceCuisine,
    articleManagement,
    upload,
    user,
    FtManagement,
    fournisseurManagement
  }
});
