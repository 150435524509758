export const namespaced = true;

export const state = {
  files: []
};

//mutation du state: permet de setter le state
export const mutations = {
  setFiles(state, data) {
    state.files = data;
  },
  shiftFiles(state) {
    state.files.splice(0, 1);
  },
  clearFiles(state) {
    state.files = [];
  }
};
