import axios from "axios";
import qs from "qs";
import { errorHandler, successHandler, getURLApi } from "../utils";
import store from "@/store";
const cfc = axios.create({
  baseURL: getURLApi() + `cfcs`,
  headers: { pragma: "no-cache" }
});

export function getUserInfo(token) {
  return cfc
    .get("/user.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "getUserInfo"
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}

export function getToken(params) {
  return cfc
    .post(
      "/Connexion.cfc",
      qs.stringify({
        method: "getToken",
        ...params
      })
    )
    .then(successHandler)
    .catch(errorHandler);
}

export function getUser(token) {
  return cfc
    .get("/user.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: { method: "getUser" }
    })
    .then(successHandler)
    .catch(errorHandler);
}

export function createUser(params) {
  return cfc
    .get("/user.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: { method: "createUser", ...params }
    })
    .then(successHandler)
    .catch(errorHandler);
}

export function checkLoginAlreadyUse(params) {
  return cfc
    .get("/user.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: { method: "checkLoginAlreadyUse", ...params }
    })
    .then(successHandler)
    .catch(errorHandler);
}
