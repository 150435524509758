import { getClient } from "@/api/clients";
import { getClients } from "@/api/clients";
import { createClient } from "@/api/clients";
import { updateClient } from "@/api/clients";
import { deleteClient } from "@/api/clients";

import { getClientParam } from "@/api/clients";
import { getClientParams } from "@/api/clients";
import { createClientParam } from "@/api/clients";
import { updateClientParam } from "@/api/clients";
import { deleteClientParam } from "@/api/clients";
import { launchFlowManual } from "@/api/clients";

export const namespaced = true;

export const state = {
  clients: [],
  clientEdited: {},
  clientParams: [],
  clientParamEdited: {}
};
export var returnID = {};
export const mutations = {
  setClients(state, data) {
    state.clients = data;
  },
  setClient(state, data) {
    state.clientEdited = data;
  },
  setClientParams(state, data) {
    state.clientParams = data;
  },
  setClientParam(state, data) {
    state.clientParamEdited = data;
  },
  setreturnID(returnID, data) {
    returnID = data;
  }
};

export const actions = {
  async fetchClient({ commit }, data) {
    commit("setClient", await getClient(data));
  },
  async fetchClients({ commit }, data) {
    commit("setClients", await getClients(data));
  },
  async fetchClientParam({ commit }, data) {
    commit("setClientParam", await getClientParam(data));
  },
  async fetchClientParams({ commit }, data) {
    commit("setClientParams", await getClientParams(data));
  },
  async LaunchManualFlow({ commit }, data) {
    return await launchFlowManual(data);
  },
  async createClient({ commit }, data) {
    return await createClient(data);
  },
  async createClientParam({ commit }, data) {
    return await createClientParam(data);
  },

  async updateClient({ commit }, data) {
    return await updateClient(data);
  },
  async updateClientParam({ commit }, data) {
    return await updateClientParam(data);
  },

  async deleteClient({ commit }, data) {
    return await deleteClient(data);
  },
  async deleteClientParam({ commit }, data) {
    return await deleteClientParam(data);
  }
};
