import axios from "axios";
import qs from "qs";
import { getURLApi, errorHandler, successHandler } from "@/utils";
import store from "@/store";
const cfc = axios.create({
  baseURL: getURLApi() + `cfcs`,
  headers: { pragma: "no-cache" }
});

export function getFiles(params) {
  return cfc
    .get("/espaceCuisine.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "getFichierCuisine",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}

export function getListCuisine(params) {
  return cfc
    .get("/espaceCuisine.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "getListCuisine",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}

export function deleteFiles(params) {
  return cfc
    .post(
      "/espaceCuisine.cfc",
      qs.stringify({
        method: "deleteFiles",
        ...params
      }),
      {
        headers: {
          Authorization: store.state.user.token
        }
      }
    )
    .then(successHandler)
    .catch(errorHandler);
}

export function updateFile(params) {
  return cfc
    .post(
      "/espaceCuisine.cfc",
      qs.stringify({
        method: "updateFile",
        ...params
      }),
      {
        headers: {
          Authorization: store.state.user.token
        }
      }
    )
    .then(successHandler)
    .catch(errorHandler);
}
export function getFileExist(params) {
  return cfc
    .get("/espaceCuisine.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "getFileExist",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
