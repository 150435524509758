<template>
  <v-snackbar
    v-model="snackbar"
    :bottom="options.y === 'bottom'"
    :top="options.y === 'top'"
    :left="options.x === 'left'"
    :right="options.x === 'right'"
    :color="options.color"
    :timeout="options.timeout"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  data: () => ({
    snackbar: false,
    options: {},
    message: ""
  }),
  methods: {
    show(message, options) {
      this.snackbar = true;
      this.message = message;
      this.options = Object.assign(
        {
          y: "top",
          x: "",
          timeout: 2000,
          color: "success"
        },
        options
      );
    }
  }
};
</script>
