import router from "./router.js";
export class APIError extends Error {
  constructor(exception) {
    super(exception);
    let exceptionType = ["accessDenied", "tokenUnknow", "timeOut"];
    let json = JSON.parse(exception.request.response);
    if (exceptionType.includes(json.exceptionType)) {
      router.go("/Login");
    }

    if (exception.request.status == 401)
      window.location.href = window.location.origin + "/index.cfm";
    throw error;
  }
}

export function getURLApi() {
  if (process.env.NODE_ENV != "development")
    return window.location.origin + "/";
  return process.env.VUE_APP_BASE;
}

export function errorHandler(error) {
  new APIError(error);
}

export function successHandler(res) {
  return res.data;
}

export function newDate(date) {
  if (!date) return;
  let [day, hour] = date.split(" ");
  let [y, m, d] = day.split("-");
  let [h, mi, s] = [0, 0, 0];
  if (hour) [h, mi, s] = hour.split(":");
  return new Date(y, --m, d, h ? h : 0, mi ? mi : 0, s ? s : 0);
}
