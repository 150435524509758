import axios from "axios";
import qs from "qs";
import { getURLApi, errorHandler, successHandler } from "@/utils";
import store from "@/store";
const cfc = axios.create({
  baseURL: getURLApi() + `cfcs`,
  headers: { pragma: "no-cache" }
});
const cfm = axios.create({
  baseURL: getURLApi() + `cfm`,
  headers: { pragma: "no-cache" }
});

/* articleManagement */
export function getAttribut(params) {
  return cfc
    .get("/listeArticles.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "getAttribut",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
export function getListArticle(params) {
  return cfc
    .post(
      "/listeArticles.cfc",
      qs.stringify({
        method: "getArticle",
        ...params
      }),
      {
        headers: {
          Authorization: store.state.user.token
        }
      }
    )
    .then(successHandler)
    .catch(errorHandler);
}
export function purgeArticle(params) {
  return cfc
    .post(
      "/listeArticles.cfc",
      qs.stringify({
        method: "purgeArticle",
        ...params
      }),
      {
        headers: {
          Authorization: store.state.user.token
        }
      }
    )
    .then(successHandler)
    .catch(errorHandler);
}

/* articleManagement - detail */
export function getInfoArticle(params) {
  return cfc
    .get("/listeArticles.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "GetInfoArticle",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
export function deleteArticleFT(params) {
  return cfc
    .post(
      "/listeArticles.cfc",
      qs.stringify({
        method: "deleteFTA",
        ...params
      }),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: store.state.user.token
        }
      }
    )
    .then(successHandler)
    .catch(errorHandler);
}

export function getInfoArticleDT(params) {
  return cfc
    .get("/listeArticles.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "GetInfoArticleDT",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}

/* articleManagement - detail - FT */
export function getInfoFT(params) {
  return cfc
    .get("/listeArticles.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "getInfoFta",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}

/* articleManagement - detail - FT */
export function getListFTA(params) {
  return cfc
    .get("/listeArticles.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "getListFTA",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
export function getListFournisseursDispo(params) {
  return cfc
    .get("/listeArticles.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "listFournisseursDispo",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
export function MajReferentiel(params) {
  return cfc
    .get("/sap.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "majReferentiel",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
export function addFTA(params) {
  return cfc
    .post("/listeArticles.cfc", params.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: store.state.user.token
      },
      params: {
        method: "addFTA",
        ...params.data
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
export function addNewVersionFTA(params) {
  return cfc
    .post("/listeArticles.cfc", params.formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: store.state.user.token
      },
      params: {
        method: "addNewVersionFta",
        ...params.data
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
export function sendModifImport(params) {
  return cfc
    .post("/listeArticles.cfc", params, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: store.state.user.token
      },
      params: {
        method: "modifMassImport",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
export function editFTA(params) {
  return cfc
    .post(
      "/listeArticles.cfc",
      qs.stringify({
        method: "editFTA",
        ...params
      }),
      {
        headers: {
          "content-type": "application/x-www-form-urlencoded",
          Authorization: store.state.user.token
        }
      }
    )
    .then(successHandler)
    .catch(errorHandler);
}
