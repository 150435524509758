<template>
  <v-app>
    <div>
      <DrawerNavBar
        v-if="withNav"
        name="Info Produit"
        :navs="menus"
        :drawerOpen.sync="drawer"
      ></DrawerNavBar>
      <NavBar
        v-if="withNav"
        :navs="menus"
        @open-drawer="drawer = true"
        @logout="logout"
      ></NavBar>
      <v-main>
        <v-container fluid class="pb-5 px-5 content">
          <router-view />
        </v-container>
      </v-main>
    </div>

    <Confirm ref="confirm"></Confirm>
    <SnackBar ref="snackBar"></SnackBar>
    <Overlay ref="Overlay"></Overlay>
    <v-footer :fixed="fixed" app>
      <span>&copy; {{ $t("copyright") }}</span>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { newDate } from "@/utils";
import Confirm from "@/components/utils/Confirm";
import SnackBar from "@/components/utils/SnackBar";
import Overlay from "@/components/utils/Overlay";
import NavBar from "@/components/nav/NavBar";
import DrawerNavBar from "@/components/nav/DrawerNavBar";
export default {
  name: "App",
  created() {
    this.setWithNav(window.localStorage.getItem("navBar"));
    this.$root.$newDate = newDate;
  },
  data() {
    return {
      drawer: false,
      fixed: false,
      items: [
        {
          icon: "bubble_chart",
          title: "Inspire"
        }
      ],
      title: "Fiches techniques articles",
      waiting: null
    };
  },

  computed: {
    ...mapState("menus", ["menus", "withNav"]),
    ...mapState("user", ["token"])
  },
  mounted() {
    this.$root.$confirm = this.$refs.confirm.open;
    this.$root.$snackBar = this.$refs.snackBar.show;
    this.$root.$overlay = {
      show: this.$refs.Overlay.show,
      hide: this.$refs.Overlay.hide
    };
  },
  methods: {
    ...mapActions("menus", ["fetchMenus"]),
    ...mapActions("user", ["getToken", "getUser"]),
    ...mapMutations("user", ["setToken"]),
    ...mapMutations("menus", ["setWithNav", "setMenus"]),
    logout() {
      this.setToken(null);
      this.setMenus([]);
      this.setWithNav(false);
      window.localStorage.clear();
      this.$router.push("/Login");
    }
  },

  components: { Confirm, SnackBar, Overlay, NavBar, DrawerNavBar }
};
</script>

<style>
html {
  overflow-y: hidden;
}
.v-application--wrap {
  overflow-y: auto;
  height: calc(100vh - 64px);
}
.v-btn--floating .v-icon {
  height: auto;
}
</style>
