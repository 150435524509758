import "babel-polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import store from "./store";
import router from "./router";
import i18n from "./plugins/i18n";

import { sync } from "vuex-router-sync";
import { Promised } from "vue-promised";

sync(store, router);

Vue.config.productionTip = false;
Vue.component("Promised", Promised);
// rend la fonction $updateRouteQuery accessible partout avec this.$update...
Vue.prototype.$updateRouteQuery = function updateRouteQuery(path, query) {
  var stArgs = {
    query: { ...this.$route.query, ...query }
  };
  if (path) {
    stArgs.path = path;
  }
  this.$router.push(stArgs); // comme extend
};

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
