import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("./views/Home.vue")
  },
  {
    path: "/Login",
    name: "Login",
    component: () => import("./views/Login.vue")
  },
  {
    path: "/clientManagement",
    name: "clientManagement",

    component: () => import("./views/clientManagement.vue")
  },
  {
    path: "/usersManagement",
    name: "usersManagement",

    component: () => import("./views/UsersManagement.vue")
  },
  {
    path: "/adminMessage",
    name: "adminMessage",

    component: () => import("./views/adminMessage.vue")
  },
  {
    path: "/cuisine",
    name: "cuisine",

    component: () => import("./views/espaceCuisine.vue")
  },
  {
    path: "/articleManagement",
    name: "articleManagement",

    component: () => import("./views/articleManagement.vue")
  },
  {
    path: "/FtManagement",
    name: "FtManagement",

    component: () => import("./views/FtManagement.vue")
  },
  {
    path: "/FtManagement/:idFt",
    name: "FtManagementDownloadFt",

    component: () => import("./views/FtManagement.vue"),
    props: true
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(async (to, from, next) => {
  let f5 = true;
  //Verifie si le token dans le store est set si on a rien soit on vient de faire un F5 soit on se log
  if (store.state.user.token) f5 = false;
  //verifie si on a un token dans le sessionStorage
  let token = window.localStorage.getItem("token");

  let tokenUrl = "";
  if (to.query && to.query.token) tokenUrl = to.query.token;

  if (token != tokenUrl && tokenUrl != "") token = tokenUrl;

  if (token && f5)
    store.commit("user/setToken", {
      token
    });
  //si pas de token et page Login : ok
  if ((to.path == "/Login" || to.name == "Login") && !store.state.user.token)
    next();
  //si pas de token on redirige sur la page de connexion
  else if (!store.state.user.token) {
    if (
      window.location.hostname == "web.reffta.qua.sodexonet.com" ||
      window.location.hostname == "reffta.sodexonet.com"
    )
      window.location = window.location.origin + "/auth/";
    else next("/Login");
  }
  //si on a un token et qu'on appel Login redirige sur Home
  else if (to.path == "/Login" && store.state.user.token) next("/");
  else {
    //si on a fait un f5, ou qu'on est aps de navbars on recupere les info user, ses sites, ses cuisineCentral et la navBar si VUE_APP_WITH_NAV
    if (f5 || !store.getters["menus/barNavs"].length) {
      resetInfo();
      window.localStorage.setItem("navBar", "true");
      await store.dispatch("menus/fetchMenus");
    }
    if (
      to.path != "/" &&
      store.getters["menus/barNavs"].findIndex(r => r.path == to.path) < 0
    ) {
      window.localStorage.setItem("navBar", "true");
      next();
    } else next();
  }
});
export default router;

async function resetInfo() {
  await store.dispatch("user/getUser").catch(() => {
    window.localStorage.clear();
  });
}
