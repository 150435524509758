import axios from "axios";
import qs from "qs";
import { getURLApi, errorHandler, successHandler } from "@/utils";
import store from "@/store";
const cfc = axios.create({
  baseURL: getURLApi() + `cfcs`,
  headers: { pragma: "no-cache" }
});
const cfm = axios.create({
  baseURL: getURLApi() + `cfm`,
  headers: { pragma: "no-cache" }
});

/* articleManagement */
export function getAttribut(params) {
  return cfc
    .get("/listeArticles.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "getAttributFt",
        ...params
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
export function getListFt(params) {
  return cfc
    .post(
      "/listeArticles.cfc",
      qs.stringify({
        method: "getListFt",
        ...params
      }),
      {
        headers: {
          Authorization: store.state.user.token
        }
      }
    )
    .then(successHandler)
    .catch(errorHandler);
}

export function getZipFile(params) {
  return cfc
    .post(
      "/extraction.cfc",
      qs.stringify({
        method: "exportFtaZip",
        ...params
      }),
      {
        headers: {
          Authorization: store.state.user.token
        }
      }
    )
    .then(successHandler)
    .catch(errorHandler);
}
