<template>
  <v-app-bar app :color="color.appBar">
    <v-app-bar-nav-icon
      color="white"
      class="hidden-md-and-up"
      @click="openDrawer"
    ></v-app-bar-nav-icon>
    <v-toolbar-items width="100%">
      <template v-for="(row, i) in navs">
        <component
          :is="row.navs ? 'NavBarMenu' : 'NavBarBtn'"
          :key="i"
          :data="row"
          :classBtn="[color.btn, i > 0 ? 'hidden-sm-and-down' : '']"
        ></component>
      </template>
    </v-toolbar-items>
    <v-spacer></v-spacer>
    <v-toolbar-items>
      <v-btn text color="white" @click="logout">
        <v-icon>mdi-exit-to-app</v-icon>
      </v-btn>
    </v-toolbar-items>
  </v-app-bar>
</template>

<script>
import NavBarMenu from "@/components/nav/NavBarMenu";
import NavBarBtn from "@/components/nav/NavBarBtn";
export default {
  props: {
    navs: { required: true, type: Array },
    color: {
      type: Object,
      default: () => ({ appBar: "primary", btn: "primary white--text" })
    }
  },

  methods: {
    logout() {
      this.$emit("logout", true);
    },
    openDrawer() {
      this.$emit("open-drawer", true);
    }
  },

  components: { NavBarMenu, NavBarBtn }
};
</script>
