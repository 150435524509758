import {
  getAttribut,
  getListFt,
  getZipFile
} from "@/api/ficheTechniqueManagement";
export const namespaced = true;

export const state = {
  listAttribut: {},
  listFt: {}
};

//mutation du state: permet de setter le state
export const mutations = {
  setAttribut(state, data) {
    state.listAttribut = data;
  },
  setListFt(state, data) {
    state.listFt = data;
  }
};

export const actions = {
  async fetchListAttribut({ commit }, data) {
    commit("setAttribut", await getAttribut(data));
  },

  async fetchListFt({ commit }, data) {
    commit("setListFt", await getListFt(data));
  },

  async getZipFile({ commit }, data) {
    return await getZipFile(data);
  }
};
