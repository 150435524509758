<template>
  <v-overlay :value="isMask" class="overlay-z">
    <div class="subheading font-weight-medium text-uppercase">
      {{ message }}
    </div>
    <v-row no-gutters>
      <v-col justify="center" align="center">
        <v-progress-circular
          color="primary"
          size="90"
          width="6"
          class="my-4 text-center"
          :indeterminate="true"
        ></v-progress-circular>
      </v-col>
    </v-row>
  </v-overlay>
</template>

<script>
export default {
  data() {
    return {
      isMask: false,
      message: null
    };
  },
  methods: {
    show(message) {
      this.isMask = true;
      this.message = message;
    },
    hide() {
      this.isMask = false;
    }
  }
};
</script>

<style scoped>
.overlay-z {
  z-index: 999 !important;
}
</style>
