import {
  getFiles,
  deleteFiles,
  getListCuisine,
  updateFile,
  getFileExist
} from "@/api/espaceCuisine";
export const namespaced = true;

export const state = {
  fichiers: [],
  listCuisine: [],
  selectedCuisine: ""
};

//mutation du state: permet de setter le state
export const mutations = {
  setFile(state, data) {
    state.fichiers = data;
  },
  setListCuisine(state, data) {
    state.listCuisine = data;
  },
  setSelectCuisine(state, data) {
    state.selectedCuisine = data;
  }
};

export const actions = {
  async fetchFile({ commit }, data) {
    commit("setFile", await getFiles(data));
  },
  async fetchListCuisine({ commit }, data) {
    commit("setListCuisine", await getListCuisine(data));
    actions.fetchFile({ commit }, { cuisineId: state.listCuisine[0] });
    commit("setSelectCuisine", state.listCuisine[0]);
  },
  deleteFile(context, data) {
    return deleteFiles(data);
  },
  async updateFile(context, data) {
    return await updateFile(data);
  },
  async getFileExist(context, data) {
    return await getFileExist(data);
  }
};
