<template>
  <v-btn
    v-bind="$attrs"
    v-html="data.label"
    :to="data.path"
    :class="classBtn"
    class="align-center justify-start border-radius-tile text-truncate"
    depressed
  ></v-btn>
</template>

<script>
export default {
  props: {
    data: Object,
    classBtn: Array
  }
};
</script>
