import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);
const dateTimeFormats = {
  fr: {
    date: {
      year: "numeric",
      month: "numeric",
      day: "numeric"
    },
    datetime: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false
    },
    short: {
      year: "numeric",
      month: "long",
      day: "numeric"
    },
    long: {
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "numeric",
      minute: "numeric"
    }
  }
};

function loadLocaleMessages() {
  const locales = require.context("../lang", true, /[A-Za-z0-9-_,\s]+\.json$/i);
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "fr",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "fr",
  messages: loadLocaleMessages(),
  dateTimeFormats
});

// import Vue from "vue";
// //import i18n multilangue
// import VueI18n from "vue-i18n";
// import fr from "@/lang/fr";

// Vue.use(VueI18n);

// //création d'un format de date
// const dateTimeFormats = {
//   fr: {
//     formatDate: {
//       month: "2-digit",
//       day: "2-digit",
//       year: "numeric"
//     }
//   }
// };

// //par defaut on est en FR
// const i18n = new VueI18n({ messages: { fr }, locale: "fr", dateTimeFormats });

// export default i18n;
