import axios from "axios";
import { errorHandler, successHandler, getURLApi } from "../utils";
import store from "@/store";
const cfc = axios.create({
  baseURL: getURLApi() + `cfcs`,
  headers: { pragma: "no-cache" }
});

export function exportFournisseurNoFt(articleId) {
  return cfc
    .get("/GestionFournisseur.cfc", {
      headers: {
        Authorization: store.state.user.token
      },
      params: {
        method: "exportFournisseurNoFt"
      }
    })
    .then(successHandler)
    .catch(errorHandler);
}
