import {
  getAttribut,
  getListArticle,
  deleteArticleFT,
  getListFTA,
  getInfoArticle,
  getInfoArticleDT,
  purgeArticle,
  getInfoFT,
  getListFournisseursDispo,
  addFTA,
  editFTA,
  addNewVersionFTA,
  MajReferentiel,
  sendModifImport
} from "@/api/articleManagement";
export const namespaced = true;

export const state = {
  listAttribut: {},
  listArticle: { articles: [], total: null },
  articleInfo: {},
  articleDT: [],
  fournisseurFTA: {},
  listFTA: [],
  listFournisseursDispo: []
};

//mutation du state: permet de setter le state
export const mutations = {
  setAttribut(state, data) {
    state.listAttribut = data;
  },
  setListArticle(state, data) {
    state.listArticle.articles = data.articles;
    state.listArticle.total = data.total;
  },
  setArticleInfo(state, data) {
    state.articleInfo = data;
  },
  setArticleInfoDT(state, data) {
    state.articleDT = data;
  },
  setInfoFT(state, data) {
    state.fournisseurFTA = data;
  },
  setListFTA(state, data) {
    state.listFTA = data;
  },
  setListFournisseursDispo(state, data) {
    state.listFournisseursDispo = data;
  }
};

export const actions = {
  async fetchListAttribut({ commit }, data) {
    commit("setAttribut", await getAttribut(data));
  },

  async fetchListArticle({ commit }, data) {
    return commit("setListArticle", await getListArticle(data));
  },
  async deleteArticleFT(context, data) {
    return await deleteArticleFT(data);
  },
  async getArticleInfo({ commit }, data) {
    commit("setArticleInfo", await getInfoArticle(data));
  },
  async getArticleInfoDT({ commit }, data) {
    commit("setArticleInfoDT", await getInfoArticleDT(data));
  },
  async articlePurge({ commit }, data) {
    return await purgeArticle(data);
  },
  async getFTInfo({ commit }, data) {
    commit("setInfoFT", await getInfoFT(data));
  },
  async fetchListFTA({ commit }, data) {
    commit("setListFTA", await getListFTA(data));
  },
  async fetchListFournisseursDispo({ commit }, data) {
    commit("setListFournisseursDispo", await getListFournisseursDispo(data));
  },
  async setFTA(context, data) {
    return await addFTA(data);
  },
  async editFTA(context, data) {
    return await editFTA(data);
  },
  async setNewFTAVersion(context, data) {
    return await addNewVersionFTA(data);
  },
  async setImportModif(context, data) {
    return await sendModifImport(data);
  },
  async sendMajReferentiel(context, data) {
    return await MajReferentiel(data);
  }
};
