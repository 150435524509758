<template>
  <v-navigation-drawer
    v-model="drawer"
    app
    disable-resize-watcher
    width="80%"
    class="drawer"
  >
    <v-list class="py-0">
      <v-list-item class="primary">
        <v-list-item-title class="text-center white--text font-weight-bold">{{
          name
        }}</v-list-item-title>
        <v-list-item-action>
          <v-btn icon @click.stop="drawer = !drawer" class="white--text">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-row no-gutters class="flex-column">
      <template v-for="(row, i) in navs">
        <v-divider :key="'divider' + i"></v-divider>
        <v-col v-if="row.navs && i != 0" sub-group no-action :key="i">
          <v-row no-gutters class="flex-column">
            <v-col>
              <v-btn
                v-html="row.label"
                block
                large
                class="d-block py-3 justify-start text-left border-radius-tile header-nav text-truncate"
                depressed
                disabled
              ></v-btn>
            </v-col>
            <v-col v-for="(data, j) in row.navs" :key="j">
              <NavBarBtn
                block
                :data="data"
                class="pl-8 d-block py-3"
                large
              ></NavBarBtn>
            </v-col>
          </v-row>
        </v-col>
        <v-col v-else-if="i != 0" class="px-0" :key="i">
          <NavBarBtn block :data="row" large class="d-block py-3"></NavBarBtn>
        </v-col>
      </template>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import NavBarBtn from "@/components/nav/NavBarBtn";

export default {
  props: {
    name: { required: true, type: String },
    navs: { required: true, type: Array },
    drawerOpen: { required: true, type: Boolean }
  },

  computed: {
    drawer: {
      get() {
        return this.drawerOpen;
      },
      set(val) {
        this.$emit("update:drawerOpen", val);
      }
    }
  },

  components: { NavBarBtn }
};
</script>

<style scoped>
.drawer {
  max-width: 400px;
}
button.header-nav.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
  background-color: #f5f5f5 !important;
}
</style>
